@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}
/* 
body{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
} */

.about-section {
  background: url(./assets/img/aboutImage.png) no-repeat left;
  background-size: 55%;
  background-color: #fdfdfd;
  overflow: hidden;
  margin-left: 100px;
  border-radius: 55%;
}

.inner-container {
  width: 55%;
  float: right;
  background-color: #fdfdfd;
  padding: 150px;
}

.inner-container h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
}

.text {
  font-size: 13px;
  color: #545454;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 40px;
}

.skills {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 13px;
}

@media screen and (max-width: 1200px) {
  .inner-container {
    padding: 80px;
  }
}

@media screen and (max-width: 1000px) {
  .about-section {
    background-size: 100%;
    padding: 100px 40px;
  }
  .inner-container {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about-section {
    padding: 0;
  }
  .inner-container {
    padding: 60px;
  }
}
.try {
  background: hsla(210, 20%, 90%, 1);
  padding: 2em 0;
  line-height: 1.6;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}
.try2 {
  background-color: hsla(210, 20%, 90%, 1);
}
@media screen and (min-width: 580px) {
  .visibleonmobile {
    display: none;
  }
}
@media screen and (max-width: 580px) {
  .visibleonmobile {
    width: 300px;
    margin-top: 50px;
    border-radius: 55%;
  }
  .about-section {
    margin-left: 0;
  }
}
