@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600&display=swap");
body {
  font-family: Poppins;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.capitalise {
  text-transform: capitalize;
}

.hidden {
  display: none;
}

.nospace {
  margin: 0;
  padding: 0;
  list-style: none;
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.justified {
  text-align: justify;
}

.inline * {
  display: inline-block;
}

.inline *:last-child {
  margin-right: 0;
}

.pushright li {
  margin-right: 20px;
}

.pushright li:last-child {
  margin-right: 0;
}

.borderedbox {
  border: 1px solid;
}

.overlay {
  position: relative;
  z-index: 1;
}

.overlay::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
}

.bgded {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.circle {
  border-radius: 50%;
  background-clip: padding-box;
}

.btn,
button.btn {
  display: inline-block;
  padding: 8px 25px 10px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid;
}

button.btn {
  cursor: pointer;
}

.clear,
.group {
  display: block;
}

.clear::before,
.clear::after,
.group::before,
.group::after {
  display: table;
  content: "";
}

.clear,
.clear::after,
.group,
.group::after {
  clear: both;
}

a {
  outline: none;
  text-decoration: none;
}

.fl_left,
.imgl {
  float: left;
}

.fl_right,
.imgr {
  float: right;
}

/* Fonts
--------------------------------------------------------------------------------------------------------------- */
body,
input,
textarea,
select {
  font-family: Verdana, Geneva, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* Forms
--------------------------------------------------------------------------------------------------------------- */
form,
fieldset,
legend {
  margin: 0;
  padding: 0;
  border: none;
}

legend {
  display: none;
}

label,
input,
textarea,
select,
button {
  display: block;
  resize: none;
  outline: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

label {
  margin-bottom: 5px;
}

:required,
:invalid {
  outline: none;
  box-shadow: none;
}

::placeholder {
  opacity: 1;
}

/* Makes sure the placeholder text is not transparent */

/* Generalise
--------------------------------------------------------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  margin: 0 0 20px 0;
  font-size: 1.4rem;
  line-height: normal;
  font-weight: normal;
  text-transform: capitalize;
}

.heading.nospace {
  margin-bottom: 0;
}

address {
  font-style: normal;
  font-weight: normal;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  border: solid;
  border-width: 1px 0 0 0;
}

.font-xs {
  font-size: 0.8rem;
}

.font-x1 {
  font-size: 1.2rem;
}

.font-x2 {
  font-size: 1.8rem;
  line-height: normal;
}

.font-x3 {
  font-size: 2.8rem;
  line-height: normal;
}

.wrapper {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  word-wrap: break-word;
}

/*
The "hoc" class is a generic class used to centre a containing element horizontally
It should be used in conjunction with a second class or ID
*/
.hoc {
  display: block;
  margin: 0 auto;
}

/* HTML 5 Overrides
--------------------------------------------------------------------------------------------------------------- */
address,
article,
aside,
figcaption,
figure,
footer,
header,
section {
  display: block;
  margin: 0;
  padding: 0;
}

/* Grid - RS-MQF 1140 V.2 - https://www.os-templates.com/free-basic-html5-templates/rs-mqf-1140
--------------------------------------------------------------------------------------------------------------- */
.one_half,
.one_third,
.two_third,
.one_quarter,
.two_quarter,
.three_quarter {
  display: inline-block;
  margin: 0 0 0 4.21052%;
  list-style: none;
}

.first {
  margin-left: 0;
  clear: left;
}

.one_quarter {
  width: 21.8421%;
}

.one_third {
  width: 30.52631%;
}

.one_half,
.two_quarter {
  width: 47.89473%;
}

.two_third {
  width: 65.26315%;
}

.three_quarter {
  width: 73.94736%;
}

/* Spacing
--------------------------------------------------------------------------------------------------------------- */
.btmspace-10 {
  margin-bottom: 10px;
}

.btmspace-15 {
  margin-bottom: 15px;
}

.btmspace-20 {
  margin-bottom: 20px;
}

.btmspace-30 {
  margin-bottom: 30px;
}

.btmspace-50 {
  margin-bottom: 50px;
}

.btmspace-80 {
  margin-bottom: 80px;
}

.rgtspace-5 {
  margin-right: 5px;
}

.rgtspace-10 {
  margin-right: 10px;
}

.rgtspace-15 {
  margin-right: 15px;
}

.rgtspace-20 {
  margin-right: 20px;
}

.rgtspace-30 {
  margin-right: 30px;
}

.inspace-5 {
  padding: 5px;
}

.inspace-10 {
  padding: 10px;
}

.inspace-15 {
  padding: 15px;
}

.inspace-20 {
  padding: 20px;
}

.inspace-30 {
  padding: 30px;
}

.overlay {
  color: #ffffff;
  background-color: inherit;
}

.overlay::after {
  color: inherit;
  background-color: rgba(0, 0, 0, 0.55);
}

.overlay.light {
  color: #474747;
}

.overlay.light::after {
  background-color: rgba(255, 255, 255, 0.7);
}

.padtop {
  padding: 80px 0 0 0;
}

/* Page Intro
--------------------------------------------------------------------------------------------------------------- */
#pageintro {
  padding: 200px 0;
}

#pageintro article {
  display: block;
  max-width: 60%;
  text-transform: capitalize;
}

#pageintro .heading {
  font-size: 5rem;
}

#pageintro footer {
  margin-top: 50px;
}

@media screen and (max-width: 750px) {
  #pageintro article {
    max-width: none;
  }

  #pageintro .heading {
    margin-bottom: 20px;
    font-size: 2.5rem;
  }
}

#breadcrumb .heading {
  margin: 0;
  font-size: 2rem;
}

#footer .heading {
  margin-bottom: 50px;
  font-size: 1.2rem;
}

@media screen and (max-width: 750px) {
  #pageintro .heading {
    margin-bottom: 20px;
    font-size: 2.5rem;
  }
}

/* Footer */
#footer .heading {
  color: #ffffff;
}

#footer hr,
#footer .borderedbox,
#footer .linklist li {
  border-color: rgba(255, 255, 255, 0.1);
}

#footer input,
#footer button {
  border-color: transparent;
}

#footer input {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
}

#footer input:focus {
  background-color: #01acc1;
}

#footer button {
  color: #ffffff;
  background-color: #01acc1;
}

#footer button:hover {
  color: inherit;
  background-color: transparent;
  border-color: inherit;
}

.btn {
  border-radius: 14px;
}

#footer input,
#footer button {
  border-radius: 12px;
}

/* Footer
--------------------------------------------------------------------------------------------------------------- */

@media screen and (max-width: 450px) {
  #points {
    padding: 30px;
  }

  #points li {
    display: block;
    float: none;
    width: 100%;
    margin-left: 0;
  }

  #points li:nth-last-child(2) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 650px) {
  .scrollable {
    display: block;
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0 0 15px 0;
    overflow: auto;
    overflow-x: scroll;
  }

  .scrollable table {
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  .inline li {
    display: block;
    margin-bottom: 10px;
  }

  .pushright li {
    margin-right: 0;
  }

  .font-x2 {
    font-size: 1.6rem;
  }

  .font-x3 {
    font-size: 1.8rem;
  }

  .heading-text {
    padding-left: 40px;
  }
}

.btn,
.btn.inverse:hover {
  color: #ffffff;
  background-color: #212529;
  border-color: grey;
}

.btn:hover,
.btn.inverse {
  color: inherit;
  background-color: transparent;
  border-color: inherit;
}

.btn {
  border-radius: 14px;
}

#footer input,
#footer button {
  border-radius: 12px;
}

#latest article figure figcaption .meta li {
  border-color: rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 650px) {
  .heading-text {
    padding-left: 190px;
  }
}

#cards_landscape_wrap-2 {
  text-align: center;
  background: #f7f7f7;
}

#cards_landscape_wrap-2 .container {
  padding-top: 80px;
  padding-bottom: 100px;
}

#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}

#cards_landscape_wrap-2 .card-flyer {
  border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box img {
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  width: 100%;
  height: 250px;
}

#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}

#cards_landscape_wrap-2 .card-flyer .text-box {
  text-align: center;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
  padding: 30px 18px;
}

#cards_landscape_wrap-2 .card-flyer {
  background: #ffffff;
  margin-top: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}

#cards_landscape_wrap-2 .card-flyer:hover {
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-top: 50px;
}

#cards_landscape_wrap-2 .card-flyer .text-box p {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
}

#cards_landscape_wrap-2 .card-flyer .text-box h6 {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto Black", sans-serif;
  letter-spacing: 1px;
  color: #176eb5;
}

.dropdown-item {
  text-align: left;
}

.cards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.card {
  background: #fff;
  width: 25em;
  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
  width: 100%;
  object-fit: cover;
}

.card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transition: all ease-in 100ms;
}

.card p {
  color: #777;
}

.card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.card-body {
  padding: 1.2em;
}

.pcard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
}

.pcard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.containerp {
  padding: 2px 16px;
}

a {
  text-decoration: none !important;
}

@media screen and (max-width: 567px) {
  .card-textprod {
    margin-bottom: 0.5em;
  }

  .card-textprod {
    text-align: left;
  }

  .heading {
    margin-left: 17px;
  }

  .mobilebtn {
    margin-left: 20px;
  }
  .taptosee {
    margin-left: 20px;
  }
  .popupM {
    display: none;
  }
}
.cardprod {
  margin: 5%;
  flex-direction: row;
}

.card-bodyprod {
  padding: 0.5em 1em;
}

.card1.cardprod img {
  max-width: 12em;
  height: 100%;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-top-left-radius: calc(0.25rem - 1px);
}

@media screen and (min-width: 992px) {
  .cardprod {
    margin-left: 290px;
  }

  .heading {
    margin-left: 50px;
  }

  .mobilebtn {
    margin-left: 210px;
  }
  .taptosee {
    margin-left: 175px;
  }
  .modal-content {
    height: 85%; /* = 100% of the .modal-dialog block */
  }
  #carouselExampleIndicators {
    display: none;
  }
  #carouselExampleIndicators1 {
    display: none;
  }
  #carouselExampleIndicators3 {
    display: none;
  }
  #carouselExampleIndicators4 {
    display: none;
  }
  #carouselExampleIndicators5 {
    display: none;
  }
}
.gallery {
  width: 1000px;
  margin: 0 auto;

  position: relative;
}

.gallery__photo {
  width: 250px;
  height: 250px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-radius: 2px;

  position: absolute;
  transition: all 0.2s;
  z-index: 10;
  outline-offset: 20px;
}

.gallery__photo--p1 {
  top: 0;
  left: 0;
}

.gallery__photo--p2 {
  top: 0;
  right: 0;
  z-index: 1;
}

.gallery__photo--p3 {
  top: 40px;
  left: 20%;
}

.gallery__photo--p4 {
  top: 60px;
  left: 40%;
}

.gallery__photo--p5 {
  top: 40px;
  left: 60%;
  z-index: 2;
}

.gallery__photo:hover {
  outline: 10px solid #a89eee;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5);
  transform: scale(1.05) translateY(-20px);
  z-index: 20;
}
.gallery:hover .gallery__photo:not(:hover) {
  transform: scale(0.9);
}
.modal-dialog {
  height: 90% !important;
}

.containerrP {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
@media only screen and (max-width: 600px) {
  .para {
    margin-right: 30px;
    text-align: center;
  }
  .head-mob {
    text-align: center;
    margin-right: 30px;
  }
}
